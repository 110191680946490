import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Icon,
  Stack,
  Tooltip,
  Text,
  IconButton,
  Flex,
} from '@chakra-ui/react';
import { FaExternalLinkAlt } from '@react-icons/all-files/fa/FaExternalLinkAlt';

import { setSelectedStructureId, selectStructureById } from '../../features/structures/structuresSlice';
import { setSelectedHydrants } from '../../features/hydrants/hydrantsSlice';
import { formatLocationDate, formatDate, formatDateFromISO } from '../../helpers/Date';

import { AssignmentBadge } from '../../components/assignments/AssignmentBadge';
import { CompletionPopover } from './CompletionPopover';
import { useNavigate } from "react-router-dom";
import ArrowUpIcon from '../../components/FigmaExport/ArrowUpIcon';
import ArrowDownIcon from '../../components/FigmaExport/ArrowDownIcon';
import { MoreableText } from '../../components/common/MoreableText';


export const ROW_TYPES = {
  STRUCTURE: "STRUCTURE",
  OCCUPANT: "OCCUPANT",
};

const AddressCell = ({ /*getValue,*/ row }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const structure = useSelector(state => selectStructureById(state, row.original.id));
  const onClick = () => {
    if (row.original.type !== ROW_TYPES.STRUCTURE) return;
    navigate('/');
    // @todo Not like this: this is a hacky way to make sure map re-centers on location
    setTimeout(() => {
      dispatch(setSelectedStructureId(row.original.id));
      dispatch(setSelectedHydrants(structure.hydrants ? structure.hydrants : []));
    }, 100);
  };
  const address = row.original.address;
  return row.original.type === ROW_TYPES.STRUCTURE ? (
    <Flex pl={`${row.depth * 2}rem`}>
      <Tooltip label={<Text>View on map <Icon as={FaExternalLinkAlt} height="0.75rem" width="0.75rem" /></Text>} placement="left">
        <Stack className="address" mr="auto" width="200px" spacing="0px" cursor="pointer" onClick={onClick} _hover={{ textDecoration: 'underline' }}>
          {(!address || Object.keys(address).length < 1) && (
            <Text>No Address Data</Text>
          )}
          {row.original.name && <Text fontWeight={600}>{row.original.name}</Text>}
          {address && address.address1 && <Text >{address.address1}</Text>}
          {address && address.address2 && <Text >{address.address2}</Text>}
          {address && address.city && <Text>{address.city}, {address.state} {address.zip}</Text>}
        </Stack>
      </Tooltip>
      {row.getCanExpand() ? (
        <IconButton
          backgroundColor="transparent"
          icon={row.getIsExpanded() ? <ArrowUpIcon /> : <ArrowDownIcon />}
          onClick={() => row.toggleExpanded()}
        />
      ) : null}
    </Flex>
  ) : (
    <Text pl={`${row.depth * 2}rem`} fontSize="md" fontWeight={500}>{row.original.name}</Text>
  );
};


const MoreableTextCell = ({ getValue, cell }) => {
  return <MoreableText text={getValue()} header={cell.column.columnDef.header} noOfLines={3} />;
};

const locationColumns = {
  header: 'Location',
  size: 30,
  columns: [{
    id: 'address',
    header: 'Address',
    size: 250,
    accessorFn: (row) => {
      // compiles address vals into string for searchable entity
      return [...(row.name ? [row.name] : []), ...Object.values(row.address ? row.address : {})].join(', ');
    },
    cell: AddressCell,
  },
  {
    id: 'type',
    header: 'Type',
    accessorKey: 'type',
    size: 50,
  },
  {
    id: 'lotNumber',
    header: 'Lot #',
    accessorKey: 'lotNumber',
    size: 60,
  },
  {
    id: 'customerZone',
    header: 'District',
    accessorKey: 'customerZone',
    size: 60,
    className: 'district'
  },
  {
    id: 'storey',
    header: 'Floors Above',
    accessorKey: 'storey',
    size: 60,
  },
  {
    id: 'storeyBelow',
    header: 'Floors Below',
    accessorKey: 'storeyBelow',
    size: 60,
  },
  {
    id: 'roofArea',
    header: 'Roof Area',
    accessorKey: 'roofArea',
    size: 60,
  },
  ]
};

const preplanColumns = {
  header: 'Preplan',
  size: 40,
  className: 'cell-center',
  columns: [
    {
      id: 'hydrants',
      header: 'Hydrants',
      accessorKey: 'hydrants',
      className: 'hydrants',
      size: 80
    },
    {
      id: 'images',
      header: 'Images',
      accessorKey: 'imageLength',
      className: 'images',
      size: 70
    },
    {
      id: 'imageAnnotations',
      header: 'Image Annotations',
      accessorKey: 'imageAnnotations',
      size: 85,
      cell: ({ getValue, row }) => (<Text fontSize="sm" fontWeight={600}>{getValue()}/{row.original.imageLength}</Text>)
    },
    {
      header: 'Completion',
      id: 'completion',
      size: 100,
      accessorFn: (row) => row.completion.completion,
      className: 'completion',
      // filter: 'between',
      cell: ({ getValue, row }) => <CompletionPopover value={getValue()} row={row} />
    },
    {
      header: 'Floor Plan',
      id: 'floorPlan',
      className: 'floorPlan',
      size: 70,
      accessorFn: (row) => row.floorPlan ? 1 : 0,
      cell: ({ getValue }) => getValue() ? <Text fontSize="sm" fontWeight={600}>Yes</Text> : ''
    },
    {
      header: 'High Hazard',
      className: 'highHazard',
      id: 'isHighHazard',
      size: 70,
      accessorFn: (row) => row.isHighHazard ? 1 : 0,
      cell: ({ getValue }) => getValue() ? <Text fontSize="sm" fontWeight={600}>Yes</Text> : ''
    },
    {
      header: 'Risk Assessment Score',
      className: 'riskAssessmentScore',
      id: 'riskAssessmentScore',
      accessorFn: (row) => row.riskAssessmentScore,
      size: 70,
      cell: ({ getValue }) => {
        return <Text fontSize="sm" fontWeight={600}>{getValue()}</Text>;
      }
    },
    {
      id: 'reviewStatus',
      header: 'Review Status',
      accessorKey: 'reviewStatus',
      size: 70,
      cell: ({ getValue }) => <Text fontSize="sm" fontWeight={600}>{getValue()}</Text>
    },
    {
      id: 'lastReviewedBy',
      header: 'Last Reviewed By',
      accessorKey: 'lastReviewedBy',
    },
    {
      header: 'Last Reviewed',
      id: 'lastReviewedOn',
      // filter: 'between',
      accessorFn: (row) => {
        return formatLocationDate(row.lastReviewedOn).getTime();
      },
      cell: ({ getValue, row }) => {
        return (<Stack className="name-date" spacing={'0'}>
          <div className="name">{row.original.lastReviewedBy}</div>
          {getValue() && (
            <div className="date">{
              formatDate(getValue())
            }</div>
          )}
        </Stack>);
      }
    },
    {
      header: 'Created',
      id: 'originalPrePlan',
      // filter: 'between',
      accessorFn: (row) => {
        return formatLocationDate(row.originalPrePlan).getTime();
      },
      cell: ({ getValue, /*row*/ }) => getValue() ? (<Stack className="name-date">
        <div className="date">{formatDate(getValue())}</div>
      </Stack>) : null
    }
  ]
};

const assignmentColumns = {
  header: 'Assignment',
  size: 30,
  columns: [
    {
      header: 'Assigned To',
      id: 'assignedTo',
      accessorKey: 'assignedTo',
      filterFn: 'equals',
      cell: ({ getValue }) => <Box maxWidth="6rem"><Text fontSize="sm">{getValue()}</Text></Box>
    },
    {
      header: 'Due',
      id: 'assignmentDueOn',
      accessorKey: 'assignmentDueOn',
      cell: ({ getValue }) => getValue() ? (
        (<Stack className="name-date">
          <div className="date">{formatDateFromISO(getValue())}</div>
        </Stack>)
      ) : null
    },
    {
      header: 'Status',
      size: 170,
      id: 'assignmentStatus',
      accessorKey: 'assignmentStatus',
      className: 'assignmentStatus',
      cell: ({ /*row,*/ getValue }) => (getValue() ? <Box minWidth="8rem"><AssignmentBadge status={getValue()} /></Box> : null)
    },
    {
      header: 'Assignment Notes',
      id: 'assignmentNotes',
      size: 170,
      accessorKey: 'assignmentNotes',
      cell: MoreableTextCell,
    }
  ]
};

const buildingColumns = {
  id: 'building',
  header: 'Building',
  columns: [
    {
      id: 'building.commercialStructure',
      size: 70,
      header: 'Commercial',
      accessorFn: (row) => (row.commercialStructure) ? 1 : 0,
      cell: ({ getValue }) => getValue() ? <Text fontSize="sm" fontWeight={600}>Yes</Text> : '',
    },
    {
      id: 'building.occupancyType',
      size: 170,
      header: 'Occupancy Type',
      accessorFn: (row) => (row.building && row.building.occupancyType) || null,
    },
    {
      id: 'building.constructionType',
      size: 170,
      header: 'Construction Type',
      accessorFn: (row) => (row.building && row.building.constructionType) || null,
    },
    {
      id: 'building.roofType',
      size: 170,
      header: 'Roof Type',
      accessorFn: (row) => (row.building && row.building.roofType) || null,
    },
    {
      id: 'building.roofConstruction',
      size: 170,
      header: 'Roof Construction',
      accessorFn: (row) => (row.building && row.building.roofConstruction) || null,
    },
    {
      id: 'building.roofMaterial',
      size: 170,
      header: 'Roof Material',
      accessorFn: (row) => (row.building && row.building.roofMaterial) || null,
    },
    {
      id: 'building.sprinklered',
      size: 170,
      header: 'Sprinklered',
      accessorFn: (row) => (row.building && row.building.sprinklered) || null,
    },
    {
      id: 'building.standPipe',
      size: 170,
      header: 'Stand Pipe',
      accessorFn: (row) => (row.building && row.building.standPipe) || null,
    },
    {
      id: 'building.fireAlarm',
      size: 170,
      header: 'Fire Alarm',
      accessorFn: (row) => (row.building && row.building.fireAlarm) || null,
    },
    {
      id: 'building.normalPopulation',
      size: 170,
      header: 'Normal Population',
      accessorFn: (row) => (row.building && row.building.normalPopulation) || null,
    },
    {
      id: 'building.hoursOfOperation',
      size: 170,
      header: 'Hours Of Operation',
      accessorFn: (row) => (row.building && row.building.hoursOfOperation) || null,
      cell: MoreableTextCell,
    },
    {
      id: 'building.ownerContact',
      size: 170,
      header: 'Owner Contact',
      accessorFn: (row) => (row.building && row.building.ownerContact) || null,
    },
    {
      id: 'building.ownerPhone',
      size: 170,
      header: 'Owner Phone',
      accessorFn: (row) => (row.building && row.building.ownerPhone) || null,
    },
    {
      id: 'building.notes',
      size: 170,
      header: 'Notes (Optional)',
      accessorFn: (row) => (row.building && row.building.notes) || null,
      cell: MoreableTextCell,
    },
    {
      id: 'building.partnerNotes',
      size: 170,
      header: 'Third-Party Notes',
      accessorFn: (row) => (row.building && row.building.partnerNotes) || null,
      cell: MoreableTextCell,
    }
  ]
};

export const allColumns = [
  locationColumns,
  preplanColumns,
  assignmentColumns,
  buildingColumns
];

export const allColumnIds = [
  ...allColumns.flatMap(c => c.columns).map(c => c.id || (typeof(c.accessorKey) === 'string' ? c.accessorKey : undefined) || c.header)
];

export const hiddenColumnIds = [
  'id',
  'lastReviewedBy',
  'imageLength',
  'originalPrePlan',
  'riskAssessmentScore',
  'storey',
  'storeyBelow',
  'roofArea',
  'building.commercialStructure',
  'building.occupancyType',
  'building.constructionType',
  'building.roofType',
  'building.roofConstruction',
  'building.roofMaterial',
  'building.sprinklered',
  'building.standPipe',
  'building.fireAlarm',
  'building.normalPopulation',
  'building.hoursOfOperation',
  'building.ownerContact',
  'building.ownerPhone',
  'building.notes',
  'building.partnerNotes',
];
