import React, { useState } from 'react';

import {
  Flex,
  Stack,
} from '@chakra-ui/react';
import { OutlineButton } from '../form/Button';
import AddIcon from '../FigmaExport/AddIcon';
import { FloorComponent } from './FloorComponent';
import ModalComponent from './ModalComponent';
import { OccupantForm } from '../form/OccupantForm';
import { AddFloorForm } from '../form/AddFloorForm';
import ListIcon from '../FigmaExport/ListIcon';
import { DebouncedInput } from './DebouncedInput';
import { DeleteFloorForm } from '../form/DeleteFloorForm';


const OccupantsPanel = ({
  selectedStructure,
  floors,
}) => {
  const DEFAULT_MODAL_STATE = {
    showModal: false,
    modal: {
      heading: null,
      body: null,
    },
  };
  const [modalState, setModalState] = useState(DEFAULT_MODAL_STATE);

  const closeModal = () => {
    setModalState(DEFAULT_MODAL_STATE);
  };

  const handleAddOccupant = (structure, convert=false, floor=null) => {
    const occupant = {
      name: convert ? structure.name : null,
      structureId: structure.id,
      address1: convert ? structure.address.address1 : null,
      address2: convert ? structure.address.address2 : null,
      city: convert ? structure.address.city : null,
      state: convert ? structure.address.state : null,
      zip: convert ? structure.address.zip : null,
      occupancyType: convert ? structure.occupancyType : null,
      sprinklered: convert ? structure.sprinklered : null,
      normalPopulation: convert ? structure.normalPopulation : null,
      ownerContact: convert ? structure.ownerContact : null,
      ownerPhone: convert ? structure.ownerPhone : null,
      notes: convert ? structure.notes : null,
      floor: floor || null,
    };

    setModalState({
      showModal: true,
      modal: {
        heading: 'Add Occupant',
        body: (
          <OccupantForm
            occupant={occupant}
            action="ADD_OCCUPANT"
            closeModal={closeModal}
          />
        )
      },
    });
  };

  const handleEditOccupant = (occupant) => {
    setModalState({
      showModal: true,
      modal: {
        heading: 'Edit Occupant',
        body: (
          <OccupantForm
            occupant={occupant}
            action="EDIT_OCCUPANT"
            closeModal={closeModal}
          />
        )
      },
    });
  };

  const handleDeleteOccupant = (occupant) => {
    setModalState({
      showModal: true,
      modal: {
        heading: 'Delete Occupant',
        body: (
          <OccupantForm
            occupant={occupant}
            action="DELETE_OCCUPANT"
            closeModal={closeModal}
          />
        )
      },
    });
  };

  const handleAddFloor = () => {
    setModalState({
      showModal: true,
      modal: {
        heading: 'Add Floor',
        body: (
          <AddFloorForm
            selectedStructure={selectedStructure}
            floors={floors}
            closeModal={closeModal}
          />
        )
      },
    });
  };

  const handleDeleteFloor = (name) => {
    setModalState({
      showModal: true,
      modal: {
        heading: 'Delete Floor',
        body: (
          <DeleteFloorForm
            selectedStructure={selectedStructure}
            floors={floors}
            name={name}
            closeModal={closeModal}
          />
        )
      },
    });
  };

  return (selectedStructure && (
    <Stack width="100%" overflow='hidden'>
      <ModalComponent
        showModal={modalState.showModal}
        toggleModal={closeModal}
        modal={modalState.modal}
      />
      <Flex gap="8px" >
        <OutlineButton
          fontSize="sm"
          color="#2C62CB"
          borderColor="#2C62CB"
          leftIcon={<ListIcon fill="#2C62CB" />}
          iconSpacing="auto"
          // onClick={() => handleReOrderFloors()}
          isDisabled
        />
        <OutlineButton
          fontSize="sm"
          color="#2C62CB"
          borderColor="#2C62CB"
          leftIcon={<AddIcon fill="#2C62CB" />}
          onClick={() => handleAddOccupant(selectedStructure, false)}
          flexGrow={1}
        >
          Add Occupant
        </OutlineButton>
        <OutlineButton
          fontSize="sm"
          color="#2C62CB"
          borderColor="#2C62CB"
          leftIcon={<AddIcon fill="#2C62CB" />}
          onClick={() => handleAddFloor(selectedStructure)}
          flexGrow={1}
        >
          Add Floor
        </OutlineButton>
      </Flex>
      <DebouncedInput
        value={''}
        onChange={value => console.log(value)}
        placeholder="Search"
        isDisabled
      />
      {floors.map((floor, index) => (
        <FloorComponent
          key={index}
          floor={floor}
          handleAddOccupant={handleAddOccupant}
          selectedStructure={selectedStructure}
          handleEditOccupant={handleEditOccupant}
          handleDeleteOccupant={handleDeleteOccupant}
          handleDeleteFloor={handleDeleteFloor}
        />
      ))}
    </Stack>
  ));
};


export default OccupantsPanel;
