import React from 'react';
import { Stack } from '@chakra-ui/react';
import StructureInfoPanel from './StructureInfoPanel';
import PropertyInfoPanel from './PropertyInfoPanel';
import { HydrantsInfoPanel } from './HydrantsInfoPanel';
import { AssignmentCard } from '../assignments/AssignmentCard';


const StructureDataPanel = ({ selectedStructure, property, handleConnectPropertyStructures }) => {
  return (selectedStructure && ( 
    <Stack gap="12px">
      <StructureInfoPanel structure={selectedStructure} />
      <PropertyInfoPanel property={property} structure={selectedStructure} handleConnectPropertyStructures={handleConnectPropertyStructures} />
      <HydrantsInfoPanel structure={selectedStructure} />
      <AssignmentCard locationId={selectedStructure.id} />
    </Stack>
  ));
};


export default StructureDataPanel;
