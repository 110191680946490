import React, { useContext } from 'react';
import {
  Box,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Button,
  Divider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  PopoverArrow,
  PopoverCloseButton,
  Portal,
  Tooltip,
  Heading,
  VStack,
  IconButton
} from '@chakra-ui/react';
import { NavLink as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaRegUser } from '@react-icons/all-files/fa/FaRegUser';
import { FaSignOutAlt } from '@react-icons/all-files/fa/FaSignOutAlt';
import { FaRegEnvelope } from '@react-icons/all-files/fa/FaRegEnvelope';

import { NavContext } from '../../containers/MainContainer';
import { ConfirmModal } from '../modal/Confirm';
import { MapSettings } from '../config/MapSettings';
import FlowLogo from '../../../svg/EliteVPPFlowMSPLogo';
import LogoNoText from '../FigmaExport/LogoNoText';
import RightActionIcon from '../FigmaExport/RightActionIcon';
import LeftActionIcon from '../FigmaExport/LeftActionIcon';
import DashboardIcon from '../FigmaExport/DashboardIcon';
import AccountIcon from '../FigmaExport/AccountIcon';
import DataUploadIcon from '../FigmaExport/DataUploadIcon';
import DataSharingIcon from '../FigmaExport/DataSharingIcon';
import SupportIcon from '../FigmaExport/SupportIcon';
import SettingsIcon from '../FigmaExport/SettingsIcon';
import CompassIcon from '../FigmaExport/CompassIcon';


const INACTIVE_COLOR = "#707A86"; // TODO move to utils and reuse everywhere
const ACTIVE_COLOR = "#2C62CB";

const linkStyle = {
  textDecoration: 'none',
  color: INACTIVE_COLOR,
  fill: INACTIVE_COLOR,
};
const activeStyle = {
  backgroundColor: '#2C62CB1A',
  textDecoration: 'none',
  borderRadius: '6px',
  color: ACTIVE_COLOR,
  fill: ACTIVE_COLOR,
};


export const Sidebar = (props) => {
  const user = useSelector(state => state.user);
  const nav = useContext(NavContext);
  return (
    <Flex
      bg={useColorModeValue('white', 'gray.900')}
      pl="10px"
      pr="9px"
      py="16px"
      borderRight="1px"
      boxShadow="sm"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={nav.isOpen ? '190px' : '74px'}
    >
      <Box
        display="flex"
        flexDirection="column"
        w="full"
        h="full"
        overflow="hidden"
      >
        <MenuHeader />
        <VStack marginBottom="auto" marginTop="2rem" gap="4px">
          <NavItem to="/" key="Map" icon={CompassIcon}>
            Map
          </NavItem>
          <NavItem to="/dashboard" key="Dashboard" icon={DashboardIcon}>
            Dashboard
          </NavItem>
          {['ADMIN'].includes(user.role) && (
            <React.Fragment>
              <NavItem to="/account-info" key="Account" icon={AccountIcon}>
                Account
              </NavItem>
              <NavItem to="/upload-data" key="Data Upload" icon={DataUploadIcon}>
                Data Upload
              </NavItem>
              <NavItem to="/data-sharing" key="Data Sharing" icon={DataSharingIcon}>
                Data Sharing
              </NavItem>
            </React.Fragment>
          )}
        </VStack>
        <VStack marginTop="auto"  gap="0.25rem">
          <Divider />
          <Support />
          <Settings />
          <Divider />
          <NavItem to="/my-profile" key="My Profile" icon={FaRegUser}>
            My Profile
          </NavItem>
          <LogoutButton logoutAction={props.logout} navOpen={nav.isOpen} />
          <Text fontSize="0.75rem" color="gray.400" mt="auto" textAlign="start">
            v5.1
          </Text>
        </VStack>
      </Box>
    </Flex>
  );
};

const MenuHeader = () => {
  const nav = useContext(NavContext);

  return (
    <VStack gap="8px" align={nav.isOpen ? "end" : "center"} >
      <Link as={RouterLink} to={'/'} style={{ textDecoration: 'none' }}>
        {nav.isOpen ? <FlowLogo width={170} height={24} /> : <LogoNoText />}
      </Link>
      <IconButton
        aria-label="Toggle Menu"
        variant="unstyled"
        icon={nav.isOpen ? <LeftActionIcon /> : <RightActionIcon />}
        size="3rem"
        padding="0rem"
        borderRadius="0"
        onClick={nav.toggleMenu}
      />
    </VStack>
  );
};

export const Support = () => {

  return (
    <Popover placement="right">
      <PopoverTrigger>
        <Button display="flex" textAlign="left" w="100%" color="inherit" fontWeight="normal" variant="link" style={linkStyle}>
          <NavIcon icon={SupportIcon}>Support</NavIcon>
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Box p="1rem">
              <Heading as="h4" fontSize="sm" mb={2}>Support</Heading>
              <Text fontSize="xs">Connect with our customer operations team: Send us an email with a brief description of how we can help.</Text>
              <Button mt={4} width="100%" size="sm" colorScheme="blue" leftIcon={<FaRegEnvelope />} variant="outline" as={Link} isExternal href="mailto:support@flowmsp.com">Contact FlowMSP Support</Button>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export const Settings = () => {

  return (
    <Popover placement="right">
      <PopoverTrigger>
        <Button display="flex" textAlign="left" w="100%" color="inherit" fontWeight="normal" variant="link" style={linkStyle}>
          <NavIcon icon={SettingsIcon}>Settings</NavIcon>
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Box p="1rem">
              <MapSettings />
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};


const NavItem = ({ icon, children, to }) => {
  return (
    <Link width='100%' as={RouterLink} end to={to} style={({ isActive }) => isActive ? activeStyle : linkStyle} _focus={{
      boxShadow: 'none'
    }}>
      <NavIcon icon={icon}>
        {children}
      </NavIcon>
    </Link>
  );
};

const NavIcon = (props) => {
  const nav = useContext(NavContext);

  return (
    <Tooltip hasArrow fontSize="1rem" padding="0.5rem" isDisabled={nav.isOpen} placement="right" label={props.children}>
      <Flex
        height="40px"
        p="19px"
        role="group"
        cursor="pointer"
        w="100%"
        alignItems="center"
        lineHeight="1.5rem"
        _hover={{
          bg: '#2C62CB1A',
          color: ACTIVE_COLOR,
          borderRadius: '6px',
        }}
        gap="0.25rem"
        fill="inherit"
      >
        {props.icon && (
          <Icon
            fontSize="1rem"
            _groupHover={{
              color: ACTIVE_COLOR,
              fill: ACTIVE_COLOR,
            }}
            as={props.icon}
            fill="inherit"
          />
        )}
        {nav.isOpen && <Text fontWeight="600" ml="3px">{props.children}</Text>}
      </Flex>
    </Tooltip>

  );
};

const LogoutButton = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Flex w="100%">
      <Button display="flex" textAlign="left" w="100%" color="inherit" fontWeight="normal" onClick={() => setIsOpen(true)} variant="link" style={linkStyle}>
        <NavIcon icon={FaSignOutAlt}>
          Logout
        </NavIcon>
      </Button>
      <ConfirmModal
        isOpen={isOpen}
        onDecline={() => setIsOpen(false)}
        onConfirm={props.logoutAction}
      >
        Are you sure you want to logout?
      </ConfirmModal>
    </Flex>
  );
};
