import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Text,
  Popover,
  useDisclosure,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  PopoverTrigger
} from '@chakra-ui/react';
import { LinkButton } from '../form/Button';


export const MoreableText = ({ text, header, noOfLines, ...props }) => {
  const [showMore, setShowMore] = useState(false);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const textRef = useRef(null);

  const calcShowMore = useCallback(() => {
    const textElement = textRef.current;
    if (textElement != null) {
      setShowMore(textElement.offsetHeight < textElement.scrollHeight);
    }
  }, [textRef]);

  useEffect(() => {
    const textElement = textRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          calcShowMore();
        }
      });
    });

    if (textElement != null) observer.observe(textElement);
    return () => {
      if (textElement != null) observer.unobserve(textElement);
    };
  }, [textRef, calcShowMore]);

  useEffect(() => {
    calcShowMore();
    window.addEventListener('resize', calcShowMore, true);

    return () => {
      window.removeEventListener('resize', calcShowMore, true);
    };
  }, [calcShowMore]);

  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Text
          ref={textRef}
          wordBreak='break-word'
          position='relative'
          noOfLines={noOfLines || 3}
          backgroundColor={ isOpen ? '#2C62CB26' : null}
          {...props}
        >
          {text}
          <span style={{ color: '#2C62CB', backgroundColor: 'white', position: 'absolute', bottom: 0, right: 0 }} hidden={!showMore}>
            <LinkButton onClick={onToggle}>+more</LinkButton>
          </span>
        </Text>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>{header || 'more'}</PopoverHeader>
        <PopoverBody>{text}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
