/* global google */

import React from 'react';

import {
  Heading,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Badge,
  useColorModeValue,
} from '@chakra-ui/react';

import { DispatchTab } from '../components/dispatch/DispatchTab';
import { PrePlanPanel } from '../components/common/PrePlanPanel';


export const TabsContainer = ({
  selectedStructure,
  selectStructureByMessage,
  handleConnectPropertyStructures,
  handleDisconnect,
  ...props }) => {


  const selectDispatch = (message) => {

    // @todo we should really create a geocoder hook
    // @todo we should provide feedback on error.
    // This checks if the message included a latLng
    // If not, we geocode the address
    if (!message.latLon && message.address) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ 'address': message.address }, (results, status) => {
        if (status === 'OK') {
          selectStructureByMessage({
            ...message,
            latLon: {
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng()
            }
          });
        }
      });
    } else {
      selectStructureByMessage(message);
    }
  };

  return (
    <Box bg={useColorModeValue('white', 'gray.900')} borderWidth="1px" borderRadius="0.5rem" >
      <DispatchTab /*isPrePlanningMode={locationDataProps.isPrePlanningMode} */selectDispatch={selectDispatch} />
      <Box>
        <Accordion defaultIndex={[0, 1]} allowMultiple>
          <AccordionItem borderTopWidth="0">
            <AccordionButton>
              <Heading textAlign="left" flex="1" as="h5" size="md">Pre-Plan</Heading>
              {selectedStructure && !selectedStructure.isMine && <Box paddingBottom="0.25rem">
                <Badge colorScheme="blue">Partner Data</Badge>
              </Box>}
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <PrePlanPanel
                handleConnectPropertyStructures={handleConnectPropertyStructures}
                handleDisconnect={handleDisconnect}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </Box>
  );
};
