import React, { useState } from 'react';
import { OutlineButton } from '../form/Button';
import EditIcon from '../FigmaExport/EditIcon';
import DeleteIcon from '../FigmaExport/DeleteIcon';
import { StructureForm } from '../form/StructureForm';
import ModalComponent from './ModalComponent';
import { MoreableText } from './MoreableText';
import { useSelector } from 'react-redux';
import {
  Stack,
  Text,
  GridItem,
  Grid,
  Divider,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { userRoleSelector } from '../../features/user/userSlice';
import { NavLink as RouterLink } from 'react-router-dom';
import PDFIcon from '../FigmaExport/PDFIcon';
import ArrowDownIcon from '../FigmaExport/ArrowDownIcon';
import MoveIcon from '../FigmaExport/MoveIcon';


export const STRUCTURE_FIELDS = [
  {
    label: 'Structure Name',
    of: (structure) => structure.name,
  },
  // {
  //   label: 'Address',
  //   of: (structure) => structure.address,
  // },
  {
    label: 'Occupancy Type',
    of: (structure) => structure.building.occupancyType,
  },
  {
    label: 'Construction Type',
    of: (structure) => structure.building.constructionType,
  },
  {
    label: 'Roof Type',
    of: (structure) => structure.building.roofType,
  },
  {
    label: 'Roof Construction',
    of: (structure) => structure.building.roofConstruction,
  },
  {
    label: 'Roof Material',
    of: (structure) => structure.building.roofMaterial,
  },
  {
    label: 'Sprinklered',
    of: (structure) => structure.building.sprinklered,
  },
  {
    label: 'Stand Pipe',
    of: (structure) => structure.building.standPipe,
  },
  {
    label: 'Fire Alarm',
    of: (structure) => structure.building.fireAlarm,
  },
  {
    label: 'Normal Population',
    of: (structure) => structure.building.normalPopulation,
  },
  {
    label: 'Hours of Operation',
    canBeLong: true,
    of: (structure) => structure.building.hoursOfOperation,
  },
  {
    label: 'Owner 1 Contact',
    of: (structure) => structure.building.ownerContact,
  },
  {
    label: 'Owner 1 Phone',
    of: (structure) => structure.building.ownerPhone,
  },
  {
    label: 'PrePlan Created Date',
    of: (structure) => structure.building.originalPrePlan,
  },
  {
    label: 'Last Reviewed Date',
    of: (structure) => structure.building.lastReviewedOn,
  },
  {
    label: 'Last Reviewed By',
    of: (structure) => structure.building.lastReviewedBy,
  },
  {
    label: 'Notes',
    canBeLong: true,
    of: (structure) => structure.notes,
  },
  {
    label: 'Third-Party Notes',
    canBeLong: true,
    of: (structure) => structure.building.partnerNotes,
    hideIfEmpty: true
  }
];


const StructureInfoPanel = ({ structure }) => {
  const DEFAULT_MODAL_STATE = {
    showModal: false,
    modal: {
      heading: null,
      body: null,
    },
  };
  const [modalState, setModalState] = useState(DEFAULT_MODAL_STATE);
  const userRole = useSelector(userRoleSelector);

  const closeModal = () => {
    setModalState(DEFAULT_MODAL_STATE);
  };

  const labelStyle = {
    fontSize: 'xs',
    textTransform: 'uppercase',
    lineHeight: '1.75rem',
    color: 'gray.700'
  };

  const handleEditStructure = (structure) => {
    setModalState({
      showModal: true,
      modal: {
        heading: 'Edit Structure',
        body: (
          <StructureForm
            structure={structure}
            action="EDIT_STRUCTURE"
            closeModal={closeModal}
          />
        )
      },
    });
  };

  const handleDeleteStructure = (structure) => {
    setModalState({
      showModal: true,
      modal: {
        heading: 'Delete Structure',
        body: (
          <StructureForm
            structure={structure}
            action="DELETE_STRUCTURE"
            closeModal={closeModal}
          />
        )
      },
    });
  };

  return (structure && ( 
    <Stack width="100%" padding="12px" borderWidth='1px' borderRadius='lg' overflow='hidden'>
      <ModalComponent
        showModal={modalState.showModal}
        toggleModal={closeModal}
        modal={modalState.modal}
      />
      <Flex gap="8px">
        <OutlineButton
          fontSize="sm"
          color="#2C62CB"
          borderColor="#2C62CB"
          leftIcon={<EditIcon fill="#2C62CB" />}
          onClick={() => handleEditStructure(structure)}
          flexGrow={1}
        >
          Edit
        </OutlineButton>
        <OutlineButton
          fontSize="sm"
          color="red"
          borderColor="red"
          leftIcon={<DeleteIcon fill="red" />}
          onClick={() => handleDeleteStructure(structure)}
          flexGrow={1}
        >
          Delete
        </OutlineButton>
      </Flex>
      <Stack spacing="">
        {STRUCTURE_FIELDS.map((field, index) => (!field.hideIfEmpty || field.of(structure)) && (
          <Grid key={index} templateColumns='2fr 3fr'>
            <GridItem><Text {...labelStyle}>{field.label}</Text></GridItem>
            <GridItem>
              {field.canBeLong ?
                <MoreableText text={field.of(structure)} header={field.label} noOfLines={2} fontWeight="600" fontSize="sm" />
                :
                <Text fontWeight="600" fontSize="sm">
                  {field.of(structure)}
                </Text>
              }
            </GridItem>
          </Grid>
        ))}
      </Stack>
      <Divider />
      <Flex paddingTop="6px" alignItems="center" justifyContent="space-between" gap="12px" width="100%">
        {(userRole === "ADMIN" || userRole === "PLANNER") &&
          <Menu>
            <MenuButton
              fontSize="sm"
              color={'#2C62CB'}
              bg={'white'}
              ml="auto"
              as={Button}
              rightIcon={<ArrowDownIcon />}
            >
              Update Pre-Plan
            </MenuButton>
            <MenuList>
              <MenuItem
                key={"menu-edit"}
                icon={<EditIcon />}
                onClick={() => handleEditStructure(structure)}
              >
                Edit Structure
              </MenuItem>
              <MenuItem
                key={"menu-redraw"}
                icon={<MoveIcon />}
                isDisabled // TODO enable
                // onClick={() => handleContextMenuItemSelect(
                //   {
                //     value: "REPLAN_LOCATION"
                //   },
                //   {
                //     structure: structure
                //   }
                // )}
              >
                Re-Plan Location
              </MenuItem>
              <MenuItem
                key={"menu-delete"}
                icon={<DeleteIcon />}
                onClick={() => handleDeleteStructure(structure)}
              >
                Delete Structure
              </MenuItem>
            </MenuList>
          </Menu>
        }
        <Button
          fontSize="sm"
          color={'#2C62CB'}
          as={RouterLink}
          end
          to={`/export/${structure.id}`}
          rightIcon={<PDFIcon />}
          bg={'white'}
        >
          Export to PDF
        </Button>
      </Flex>
    </Stack>
  ));
};


export default StructureInfoPanel;
